import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel } from '@/components/common/MarkUp';
import { visuallyHiddenStyle } from '@/components/common/utils';
import { Section } from '@/components/layout/Section';
import { labelLarge } from '@/styles/typography';

import type { SanityPartnerLogosType } from '@/types/sanity';

export interface PartnerLogosProps extends SanityPartnerLogosType {}

export const PartnerLogos = ({
  hiddenHeading,
  isHeadingVisible = false,
  logos,
  backgroundColour,
}: PartnerLogosProps) => {
  const backgroundColourToken =
    backgroundColour?.token || '--background-subtle';

  return (
    <Section
      backgroundColour={backgroundColourToken}
      verticalPadding
      className="partner-logos"
    >
      {hiddenHeading && (
        <HeadingLevel>
          <MainHeading isHeadingVisible={isHeadingVisible}>
            {hiddenHeading}
          </MainHeading>
        </HeadingLevel>
      )}
      <LogosContainer>
        {logos.map((logo) => {
          return <Logo key={logo._key} {...logo.logoImage} />;
        })}
      </LogosContainer>
    </Section>
  );
};

const headingVisibility = (isHeadingVisible) =>
  isHeadingVisible
    ? css`
        ${labelLarge}
      `
    : visuallyHiddenStyle;

const MainHeading = styled(Heading)<{ isHeadingVisible: boolean }>`
  ${({ isHeadingVisible }) => headingVisibility(isHeadingVisible)}
  text-align: center;
  color: var(--text-action);
`;

const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xx-small) var(--spacing-300);
  align-items: center;
  justify-content: space-around;

  margin-top: var(--spacing-medium);

  ${screen.sm} {
    gap: var(--spacing-small) var(--spacing-400);
  }

  ${screen.md} {
    gap: var(--spacing-xx-small) var(--spacing-500);
  }

  ${screen.lg} {
    min-width: 120px;
  }
`;

const Logo = styled(Image)`
  height: 40px;
  width: auto;

  ${screen.sm} {
    height: 48px;
  }

  ${screen.lg} {
    height: 56px;
  }
`;
